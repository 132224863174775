import ProductStockRequest from './ProductStockRequest.vue'
import ProductStockRequestCreate from './Create.vue'
import ProductStockRequestEdit from './Create.vue'
import ProductStockRequestShow from './Show.vue'

export default [
    {
        path: '/product_stock_request',
        name: 'ProductStockRequest',
        component: ProductStockRequest
    },
    {
        path: '/product_stock_request/create',
        name: 'ProductStockRequestCreate',
        component: ProductStockRequestCreate
    },
    {
        path: '/product_stock_request/edit/:id',
        name: 'ProductStockRequestEdit',
        component: ProductStockRequestEdit
    },
    {
        path: '/product_stock_request/show/:id',
        name: 'ProductStockRequestShow',
        component: ProductStockRequestShow
    },
]
